@import url(https://fonts.googleapis.com/css?family=Montserrat:900|Lato:200);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-container{
  float: right;
  padding-right: 10%;
}

.image{
  height: 18vw;
  border-radius: 47%;
  margin-bottom: 50px;
}

.HomePage {
  background-color: rgba(207, 216, 214, 0.8);
  padding: 20vh;
  min-height: 100vh;
  display:flex;
  background-image:url(/static/media/triangles.d3b98415.svg);
  background-size: cover;
}

.HomePage-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 4vw;
  color: #1F1512;
  line-height: normal;
}

.HomePage-typewriter{
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 3vw;
  display:flex !important;
  flex-wrap: wrap;
}

.HomePage-regular-text{
  font-family: 'Lato', sans-serif;
  color: #707070;
  font-size: calc(3px + 2vmin);
}

.HomePage-buttons-container{
  /*justify-content: space-between;*/
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

