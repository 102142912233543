@import url('https://fonts.googleapis.com/css?family=Montserrat:900|Lato:200');

.image-container{
  float: right;
  padding-right: 10%;
}

.image{
  height: 18vw;
  border-radius: 47%;
  margin-bottom: 50px;
}

.HomePage {
  background-color: rgba(207, 216, 214, 0.8);
  padding: 20vh;
  min-height: 100vh;
  display:flex;
  background-image:url(../images/triangles.svg);
  background-size: cover;
}

.HomePage-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 4vw;
  color: #1F1512;
  line-height: normal;
}

.HomePage-typewriter{
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 3vw;
  display:flex !important;
  flex-wrap: wrap;
}

.HomePage-regular-text{
  font-family: 'Lato', sans-serif;
  color: #707070;
  font-size: calc(3px + 2vmin);
}

.HomePage-buttons-container{
  /*justify-content: space-between;*/
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
